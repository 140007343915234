import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';  // Utilisation de Routes à la place de Switch
import App from './App';  // Page principale
import Assurances from './Assurances';  // Page Assurances
import About from './About';  // Page About (À propos)
import Blog from './Blog';  // Page Blog
import Rdv from './Rdv';  // Page de prise de rendez-vous (rdv.jsx)
import Contact from './Contact';  // Page Contact
import MentionsLegales from './MentionsLegales';  // Page Mentions Légales
import PolitiqueConfidentialite from "./PolitiqueConfidentialite";

// Rendu de l'application avec React Router
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>  {/* Utilisation de Routes au lieu de Switch */}
        {/* Définition des différentes routes */}
        <Route path="/" element={<App />} />  {/* Route pour la page principale */}
        <Route path="/assurances" element={<Assurances />} />  {/* Route pour la page Assurances */}
        <Route path="/about" element={<About />} />  {/* Route pour la page À propos */}
        <Route path="/blog" element={<Blog />} />  {/* Route pour le Blog */}
        <Route path="/contact" element={<Contact />} />  {/* Route pour la page Contact */}
        <Route path="/Rdv" element={<Rdv />} />  {/* Route pour la page de prise de rendez-vous (rdv.jsx) */}
        <Route path="/mentions-legales" element={<MentionsLegales />} />  {/* Route pour la page Mentions Légales */}
        <Route path="/politique-confidentialite" element={<PolitiqueConfidentialite/>} />
      </Routes>
    </Router>
  </React.StrictMode>
);
