import React from 'react'
import LandingPage from './templates/LandingPage'
import SectionHero from './sections/SectionHero';
import SectionAdvantagesSubscription from './sections/SectionAdvantagesSubscription';
import SectionContactSteps from './sections/SectionContactSteps';

function Contact() {
    return (
        <LandingPage>
            <SectionHero />
            <SectionAdvantagesSubscription />
            <SectionContactSteps/>
        </LandingPage>
    )
}

export default Contact
