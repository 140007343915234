import React, { useState } from 'react'
import "../styles/SectionContactSteps.css"
import buttonWhiteIcon from "../assets/ButtonIconWhite.svg"
import buttonGreenIcon from "../assets/ButtonIconGreen.svg"
import MaxWidth from '../components/MaxWidth'
import IconSimpleArrowRight from '../assets/icons/IconSimpleArrowRight'
import FloatingLogo from '../components/FloatingLogo'
import Spacer from '../components/Spacer'
import InputText from '../components/InputText'
import IconSimplePerson from '../assets/icons/IconSimplePerson'
import IconSimpleAt from '../assets/icons/IconSimpleAt'
import IconSimpleSmartPhone from '../assets/icons/IconSimpleSmartPhone'
import IconSimpleArrowLeft from '../assets/icons/IconSimpleArrowLeft'

function SectionContactSteps() {
    const [activeButton, setActiveButton] = useState('oui');
    return (
        <section className='sectionContactSteps'>
            <MaxWidth>
                <div className='contactSteps-inner'>
                    <div className='steps'>
                        <div className='steps-item'>
                            <img src={buttonWhiteIcon} alt="Button Icon" />
                            <p>étape 1</p>
                        </div>
                        <IconSimpleArrowRight fill={"#E5EEFF"} stroke={"var(--fp-color-blue-main)"} />
                        <div className='steps-item'>
                            <img src={buttonGreenIcon} alt="Button Icon" />
                            <p>étape 2</p>
                        </div>
                        <IconSimpleArrowRight fill={"#E5EEFF"} stroke={"var(--fp-color-blue-main)"} />
                        <div className='steps-item'>
                            <img src={buttonWhiteIcon} alt="Button Icon" />
                            <p>étape 3</p>
                        </div>
                    </div>

                    <div className='contactForm'>
                        <div className='form-top'>
                            <div className='form-subtitle'>
                                <img src={buttonGreenIcon} alt="Button Icon" />
                                <p>étape 1</p>
                            </div>

                            <Spacer size={"10px"} />

                            <h2 className='fp-text-style-header-large'>
                                Renseignez vos informations
                            </h2>
                        </div>

                        <div className='contactForm-form'>
                            <InputText
                                placeholder={"Prénom"}
                                name={"foreName"}
                                icon={<IconSimplePerson fill={"var(--fp-comp-input-text-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />}
                                isWhite={true}
                            />
                            <InputText
                                placeholder={"Nom"}
                                name={"name"}
                                icon={<IconSimplePerson fill={"var(--fp-comp-input-text-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />}
                                isWhite={true}
                            />
                            <InputText
                                placeholder={"Address email"}
                                name={"email"}
                                icon={<IconSimpleAt fill={"var(--fp-comp-input-text-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />}
                                isWhite={true}
                            />
                            <InputText
                                placeholder={"Numéro de téléphone"}
                                name={"phone"}
                                icon={<IconSimpleSmartPhone fill={"var(--fp-comp-input-text-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />}
                                isWhite={true}
                            />
                            <InputText
                                placeholder={"Nom de la société"}
                                name={"companyName"}
                                icon={<IconSimpleAt fill={"var(--fp-comp-input-text-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />}
                                isWhite={true}
                            />

                            <div className='yesNo-item'>
                                <p>
                                    Avez vous un litige en cours?
                                </p>

                                <div className='buttons'>
                                    <button
                                        className={activeButton === 'oui' ? 'active' : ''}
                                        onClick={() => setActiveButton('oui')}>
                                        Oui
                                    </button>
                                    <button
                                        className={activeButton === 'non' ? 'active' : ''}
                                        onClick={() => setActiveButton('non')}>
                                        Non
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className='contactForm-action-button'>
                            <button className='prev-button'>
                                <IconSimpleArrowLeft fill={"#E5EEFF"} stroke={"var(--fp-color-blue-main)"} />
                                <p>précedent</p>
                            </button>

                            <button className='next-button'>
                                <p>suivant</p>
                                <IconSimpleArrowRight fill={"#fff"} stroke={"var(--fp-color-blue-main)"} />
                            </button>
                        </div>
                    </div>
                </div>
            </MaxWidth>

            <FloatingLogo isWhite={false} />
        </section>
    )
}

export default SectionContactSteps
