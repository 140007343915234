import './App.scss';
import SectionAdvantagesSubscription from './sections/SectionAdvantagesSubscription';
import SectionContact from './sections/SectionContact';
import SectionFAQ from './sections/SectionFAQ';
import SectionRdv from './sections/SectionRdv';
import SectionHeroRdv from './sections/SectionHeroRdv';
import SectionRegistration from './sections/SectionRegistration';
import SectionReviews from './sections/SectionReviews';
import LandingPage from './templates/LandingPage';
import {Toaster} from 'react-hot-toast'
function App() {
  return (
    <div className="App">
      <LandingPage>
        <SectionHeroRdv/>
        <SectionRdv/>
        <SectionAdvantagesSubscription/>
        <SectionRegistration/>
        <SectionFAQ/>
        <SectionReviews/>
      </LandingPage>
    <Toaster/>
    </div>
  );
}

export default App;
