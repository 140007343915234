import React from 'react';
import IconSimpleSend from '../assets/icons/IconSimpleSend';
import Grid from '../components/Grid';
import GridCell from '../components/GridCell';
import HeroCard from '../components/HeroCard';
import MaxWidth from '../components/MaxWidth';

function SectionHeroRdv() {
  return (
    <div style={{ padding: "var(--fp-size-section-padding-v) 0", backgroundColor: "var(--fp-color-blue-degree-95)" }}>
      <MaxWidth>
        <Grid gutter={16} maxColumns={2}>
          <GridCell>
            <div style={{ height: "368px" }}>
              <HeroCard
                title={
                  <>
                    <span className='fp-text-style-header-big'>Prenez&nbsp;</span>
                    <span className='fp-text-style-header-big-special'>Rendez-vous</span>
                    <br />
                    <span className='fp-text-style-header-big'>Avec nos experts</span>
                  </>
                }
                description={
                  "Planifiez facilement un rendez-vous avec nos experts pour discuter de vos besoins en assurance. Chez Fast Protect, nous vous accompagnons dans chaque étape avec simplicité et rapidité."
                }
                primaryAction={{
                  icon: <IconSimpleSend fill={"var(--fp-comp-hero-card-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />,
                  action: "Prendre RDV",
                  onClick: () => { console.log("primary action") }
                }}
                secondaryAction={{
                  icon: <IconSimpleSend fill={"var(--fp-comp-hero-card-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />,
                  action: "Nous contacter",
                  onClick: () => { console.log("secondary action") }
                }}
              />
            </div>
          </GridCell>
        </Grid>
      </MaxWidth>
    </div>
  );
}

export default SectionHeroRdv;
