import MaxWidth from "../components/MaxWidth"
import SectionLabel from "../components/SectionLabel"
import Spacer from "../components/Spacer"
import IconSimpleSend from "../assets/icons/IconSimpleSend";
import SplitView from "../components/SplitView";
import InputText from "../components/InputText";
import { useState } from "react";
import IconSimplePerson from "../assets/icons/IconSimplePerson";
import IconSimpleAt from "../assets/icons/IconSimpleAt";
import IconSimpleSmartPhone from "../assets/icons/IconSimpleSmartPhone";
import ButtonAction from "../components/ButtonAction";
import FloatingLogo from "../components/FloatingLogo";
import "../styles/SectionContact.css"
import axios from "axios"
import toast from "react-hot-toast";

function SectionContact() {
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    phone: ""
  });

  const [loading, setLoading] = useState(false);
  const onValueChange = (e) => {
    const { name, value } = e.target; // Destructure directly from the event parameter
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();


    const { firstName, lastName, company, email, phone } = formValues;

    if (!firstName || !lastName || !email || !phone) return;
    setLoading(true);
    try {
      const hubspotResponse = await submitHubspotForm(firstName, lastName, company, email, phone);
      toast.success(hubspotResponse.data.inlineMessage);
      resetForm(); // Reset the form state
    } catch (err) {

      toast.error("Failed to submit the form."); // Error message
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const submitHubspotForm = async (firstName, lastName, company, email, phone) => {
    const portalId = '144634511'; // Replace with actual portal ID
    const formGuid = 'fe9c1e7b-195f-4c17-b925-b61f383c9196'; // Replace with actual form GUID
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
      {
        portalId,
        formGuid,
        fields: [
          { name: 'firstname', value: firstName },
          { name: 'lastname', value: lastName },
          { name: 'company', value: company },
          { name: 'email', value: email },
          { name: 'phone', value: phone },
        ],
      },
      config
    );

    return response;
  };

  const resetForm = () => {
    setFormValues({
      firstName: "",
      lastName: "",
      company: "",
      email: "",
      phone: ""
    });
  };
  return (
    <div id="contactForm" className="sectionContact" style={{ padding: "var(--fp-size-section-padding-v) 0", backgroundColor: "var(--fp-color-white)", position: "relative" }}>
      <MaxWidth>
        <div style={{ position: "relative", zIndex: "1" }}>
          <SectionLabel
            icon={<IconSimpleSend fill={"var(--fp-comp-section-label-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />}
            title={"Contact"}
            isWhite={true}
          />
          <Spacer size={"16px"} />
          <h2 className='fp-text-style-header-large'>
          Nos experts à votre disposition
            <br />
            Contactez nous en 1 click!
          </h2>
          <Spacer size={"48px"} />

          <form onSubmit={handleSubmit} className="contactForm">
            <InputText
              placeholder={"Prénom"}
              name={"firstName"}
              value={formValues.firstName}
              onChange={(e) => onValueChange(e)} // Pass the event object directly
              icon={<IconSimplePerson fill={"var(--fp-comp-input-text-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />}
              isWhite={true}
            />
            <InputText
              placeholder={"Nom"}
              name={"lastName"}
              value={formValues.lastName}
              onChange={(e) => onValueChange(e)} // Pass the event object directly
              icon={<IconSimplePerson fill={"var(--fp-comp-input-text-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />}
              isWhite={true}
            />
            <InputText
              placeholder={"Nom de l'entreprise (Facultatif )"}
              name={"company"}
              value={formValues.company}
              onChange={(e) => onValueChange(e)} // Pass the event object directly
              icon={<IconSimpleAt fill={"var(--fp-comp-input-text-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />}
              isWhite={true}
            />
            <InputText
              placeholder={"E-mail"}
              name={"email"}
              value={formValues.email}
              onChange={(e) => onValueChange(e)} // Pass the event object directly
              icon={<IconSimpleAt fill={"var(--fp-comp-input-text-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />}
              isWhite={true}
            />
            <InputText
              placeholder={"Numéro de téléphone"}
              name={"phone"}
              value={formValues.phone}
              onChange={(e) => onValueChange(e)} // Pass the event object directly
              icon={<IconSimpleSmartPhone fill={"var(--fp-comp-input-text-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />}
              isWhite={true}
            />
            <ButtonAction
              title={loading ? "Sending..." : "Send"}
              icon={<IconSimpleSend fill={"var(--fp-comp-button-action-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />}
              isSpecial={true}
              isFullWidth={true}
            />
          </form>
        </div>
      </MaxWidth>

      <FloatingLogo isWhite={false} />
    </div>
  );
}

export default SectionContact;